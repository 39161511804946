import { Component, OnInit } from '@angular/core';
import { ApiService } from '../services/api.service';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-script-tags',
  templateUrl: './script-tags.component.html',
  styleUrls: ['./script-tags.component.css']
})
export class ScriptTagsComponent implements OnInit {
  queryParamsLogin:any={};
  selectedOperation: string = ''; // Tracks the selected operation
  inputData: any = {}; // Holds input values
  responseMessage: string = ''; // Response message from API
  getResponseReceived: boolean = false; 
  responseData: any[] = [];
  isGet:boolean = false;
  constructor(private apiService: ApiService,private route: ActivatedRoute) { 
    this.route.queryParams.subscribe(params => {
      this.queryParamsLogin = params
      // console.log('Custom Data:', this.queryParamsLogin);
    });
  }

  ngOnInit(): void {
  }

  // Handle GET request
  getData(): void {
    this.apiService.getItems(this.queryParamsLogin,this.inputData).subscribe(
      (response) => {
        console.log("getScript response:",response);
        this.responseData = response;
        this.responseMessage = `Retrieved script  tag info successfully with domain ${this.inputData.shopifyDomain}`;
        this.getResponseReceived = true; // Show the Switch Tag button
        this.isGet = false;
      },
      (error) => {
        console.log("complete error message:",error);
        this.responseMessage = 'Error: Unable to fetch data.';
      }
    );
  }

  // Handle CREATE request
  createData(): void {
    this.apiService.createItem(this.queryParamsLogin,this.inputData).subscribe(
      (response) => {
        console.log("create Script response:",response);
        this.responseMessage = `Created new script tag successfully with domain ${this.inputData.shopifyDomain}`;
        this.isGet = true;
        setTimeout(() => this.getData(), 100);
      },
      (error) => {
        console.log("complete error message:",error);
        this.responseMessage = 'Error: Unable to create item.';
      }
    );
  }

  // Handle UPDATE request
  updateData(): void {
    this.apiService.updateItem(this.queryParamsLogin,this.inputData).subscribe(
      (response) => {
        console.log("update Script response:",response);
        this.responseMessage = `Updated script tag successfully with domain ${this.inputData.shopifyDomain} and script tag ID ${this.inputData.scriptTagID}`;
        this.isGet = true;
        setTimeout(() => this.getData(), 100);
      },
      (error) => {
        console.log("complete error message:",error);
        this.responseMessage = 'Error: Unable to update item.';
      }
    );
  }

  // Handle DELETE request
  deleteData(): void {
    this.apiService.deleteItem(this.queryParamsLogin,this.inputData).subscribe(
      (response) => {
        console.log("delete Script response:",response);
        this.responseMessage = `Deleted script tag ID ${this.inputData.scriptTagID} successfully with domain ${this.inputData.shopifyDomain}`;
        this.isGet = true;
        setTimeout(() => this.getData(), 100);
      },
      (error) => {
        console.log("complete error message:",error);
        this.responseMessage = 'Error: Unable to delete item.';
      }
    );
  }

  // Reset the UI and message
  resetUI(): void {
    this.inputData = {};
    this.responseMessage = '';
    this.responseData = [];
    this.getResponseReceived = false;
  }

  setOperation(operation: string): void {
    this.selectedOperation = operation;
    // this.resetUI(); // Reset the UI logic if needed
  }

  reloadHome(): void {
    window.location.href = '/';
  }

  onSwitchTag(row: any): void {
    console.log('Switch Tag triggered for:', row);
    const data = {
      id: row.id,
      src: row.src,
      shopifyDomain: this.inputData.shopifyDomain,
    };
    this.apiService.switchTagRequest(this.queryParamsLogin,data).subscribe(
      (response) => {
        console.log("switch Script tag response:",response);
        this.responseMessage = `Script tag  changed to production successfully with domain ${this.inputData.shopifyDomain} and script tag ID ${row.id}`;
        this.isGet = true;
        setTimeout(() => this.getData(), 100);
      },
      (error) => {
        console.log("complete error message:",error);
        this.responseMessage = 'Error: Unable to change script tag to prod.';
      }
    );
  }
  

}
