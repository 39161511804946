import { NgModule } from '@angular/core';
import { RouterModule, Routes, ExtraOptions } from '@angular/router';
import { ScriptTagsComponent } from './script-tags/script-tags.component';
import { AuthLoginComponent } from './auth-login/auth-login.component';

const routes: Routes = [
  { path: '', component: AuthLoginComponent }, // Homepage route
  { path: 'script-tags', component: ScriptTagsComponent },
  { path: '**', redirectTo: '/', pathMatch: 'full' } // Catch-all for invalid paths
];

const routerOptions: ExtraOptions = {
  onSameUrlNavigation: 'reload',  // Ensures page reloads on the same URL
  paramsInheritanceStrategy: 'always', // Inherit query parameters
};
@NgModule({
  imports: [RouterModule.forRoot(routes, routerOptions)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
