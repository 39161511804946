import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from '../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class ApiService {
  apiURL :string = environment.apiEndPoint;
  appName :string = environment.appName;
  private baseUrl = this.apiURL+'/lb4_store_script';
  private headers = {
    'Content-Type': 'application/json',
    realmname: '',
    Authorization: ''
  };
  constructor(private http: HttpClient) { }

  // getting script tag
  getItems(tokenInfo:any,data:any): Observable<any>{
    let inputData = {
      "shop_domain":data.shopifyDomain,
      "app_name":this.appName
    }
    this.headers.realmname = tokenInfo.param1;
    this.headers.Authorization = 'Bearer ' + JSON.parse(tokenInfo.param2).access_token;
    return this.http.post(this.baseUrl+'/getStoreScriptGql', inputData,{ headers:this.headers });
  }

  // CREATE new script tag
  createItem(tokenInfo:any,data:any): Observable<any> {
    let inputData = {
      "shop_domain":data.shopifyDomain,
      "app_name":this.appName,
      "src":data.scriptTagUrl
    }
    this.headers.realmname = tokenInfo.param1;
    this.headers.Authorization = 'Bearer ' + JSON.parse(tokenInfo.param2).access_token;
    return this.http.post(this.baseUrl+'/createStoreScriptGql', inputData,{ headers:this.headers });
  }

  // UPDATE an existing script tag
  updateItem(tokenInfo:any,data:any): Observable<any> {
    let inputData = {
      "shop_domain":data.shopifyDomain,
      "app_name":this.appName,
      "tagId":data.scriptTagID,
      "src":data.scriptTagUrl
    }
    this.headers.realmname = tokenInfo.param1;
    this.headers.Authorization = 'Bearer ' + JSON.parse(tokenInfo.param2).access_token;
    return this.http.post(this.baseUrl+'/updateStoreScriptGql', inputData,{ headers:this.headers });
  }

  // DELETE script tag
  deleteItem(tokenInfo:any,data:any): Observable<any> {
    let inputData = {
      "shop_domain":data.shopifyDomain,
      "app_name":this.appName,
      "tagId":data.scriptTagID
    }
    this.headers.realmname = tokenInfo.param1;
    this.headers.Authorization = 'Bearer ' + JSON.parse(tokenInfo.param2).access_token;
    return this.http.post(this.baseUrl+'/deleteStoreScriptGql', inputData,{ headers:this.headers });
  }

  switchTagRequest(tokenInfo:any,data:any): Observable<any> {
    let inputData = {
      "shop_domain":data.shopifyDomain,
      "app_name":this.appName,
      "tagId":data.id,
      "src":data.src
    }
    this.headers.realmname = tokenInfo.param1;
    this.headers.Authorization = 'Bearer ' + JSON.parse(tokenInfo.param2).access_token;
    return this.http.post(`${this.baseUrl}/switchScriptTagToProd`, inputData,{ headers:this.headers });
  }
}

