import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { AuthLoginComponent } from './auth-login/auth-login.component';

// Import the module from the SDK
import { ClipboardModule } from "@angular/cdk/clipboard";
import { AuthHttpInterceptor, AuthModule } from '@auth0/auth0-angular';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { ScriptTagsComponent } from './script-tags/script-tags.component';

@NgModule({
  declarations: [
    AppComponent,
    AuthLoginComponent,
    ScriptTagsComponent,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    FormsModule,
    ReactiveFormsModule,
    ClipboardModule,
    HttpClientModule,
    // Import the module into the application, with configuration
    //  test auth0 config
    // AuthModule.forRoot({
    //   domain: 'firstauthtest.us.auth0.com',
    //   clientId: 'imXu9wF4lx4l1hapW9qIygHpoHwPHpu6',
    //   scope: 'openid profile email user_metadata app_metadata'
    // }),
    // prod auth0 config
    AuthModule.forRoot({
      domain: 'dev-lhddn5tc.us.auth0.com',
      clientId: 'RAXLCHqKChP1506Uyh6272DUROyQ2VZq',
      scope: 'openid profile email user_metadata app_metadata'
    }),
  ],
  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthHttpInterceptor,
      multi: true,
    },
    {
      provide: Window,
      useValue: window,
    },
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
