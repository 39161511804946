<h1 class="larfTitle">Welcome to Livearf Employee Login to update Shopify Script</h1>
<div class="container mt-4">
  <h2 class="mb-4">Script tag Operations</h2>
  <div style="display: flex; align-items: center; gap: 10px;">
    <h4 class="mb-4" style="margin: 0;">How to get Shopify domain from client stores?</h4>
    <div class="info-icon" style="position: relative; display: inline-block; cursor: pointer;">
      ℹ️<span class="info-content">
        <p style="margin: 10px 0 0;"><strong>Example to get Shopify domain</strong></p>
        <br>
        <img src="https://cdn.shopify.com/s/files/1/0675/6646/9345/files/getShop.png?v=1736177437"
          alt="Reference to get shop domain" style="max-width: 100%; max-height: 100%; object-fit: contain;"> 
      </span>
    </div>
  </div>
  <ul>
    <li  class="letter-spacing">Go to clients shopify webpage and open console.</li>
    <li >Type Shopify.shop and press enter, then copy paste shop domain in corresponding input field. </li>
  </ul>
  <br>
  <!-- Operation Selection Buttons -->
  <div class="mb-3">
    <button class="btn mr-2"
      [ngClass]="{'btn-info active-button': selectedOperation === 'GET', 'btn-info': selectedOperation !== 'GET'}"
      (click)="setOperation('GET'); resetUI()">
      GET
    </button>

    <button class="btn mr-2"
      [ngClass]="{'btn-success active-button': selectedOperation === 'CREATE', 'btn-success': selectedOperation !== 'CREATE'}"
      (click)="setOperation('CREATE');">
      CREATE
    </button>

    <button class="btn mr-2"
      [ngClass]="{'btn-warning active-button': selectedOperation === 'UPDATE', 'btn-warning': selectedOperation !== 'UPDATE'}"
      (click)="setOperation('UPDATE');">
      UPDATE
    </button>

    <button class="btn"
      [ngClass]="{'btn-danger active-button': selectedOperation === 'DELETE', 'btn-danger': selectedOperation !== 'DELETE'}"
      (click)="setOperation('DELETE');">
      DELETE
    </button>
  </div>


  <!-- GET Operation UI -->
  <div *ngIf="selectedOperation === 'GET'" class="mb-3">
    <label for="getShopDomain">Enter Shopify Domain:</label>
    <input type="text" id="getShopDomain" [(ngModel)]="inputData.shopifyDomain" class="form-control mb-2"
      placeholder="Enter Shopify Domain" />
    <button class="btn btn-primary" (click)="getData()">Fetch Data</button>
    <p *ngIf="responseMessage" class="mt-2 text-info">{{ responseMessage }}</p>
  </div>

  <!-- CREATE Operation UI -->
  <div *ngIf="selectedOperation === 'CREATE'" class="mb-3">
    <label for="getShopDomain">Enter Shopify Domain:</label>
    <input type="text" id="getShopDomain" [(ngModel)]="inputData.shopifyDomain" class="form-control mb-2"
      placeholder="Enter Shopify Domain" /><br>
    <label for="scriptTagUrl">Enter Script Tag Url:</label>
    <input type="text" id="scriptTagUrl" [(ngModel)]="inputData.scriptTagUrl" class="form-control mb-2"
      placeholder="Enter Script Tag Url" />
    <button class="btn btn-success" (click)="createData()">Create Data</button>
    <p *ngIf="responseMessage" class="mt-2 text-info">{{ responseMessage }}</p>
    <p *ngIf="isGet">Please wait... Getting latest script tag info...</p>
  </div>

  <!-- UPDATE Operation UI -->
  <div *ngIf="selectedOperation === 'UPDATE'" class="mb-3">
    <label for="getShopDomain">Enter Shopify Domain:</label>
    <input type="text" id="getShopDomain" [(ngModel)]="inputData.shopifyDomain" class="form-control mb-2"
      placeholder="Enter Shopify Domain" /><br>
    <label for="scriptTagUrl">Enter Script Tag Url:</label>
    <input type="text" id="scriptTagUrl" [(ngModel)]="inputData.scriptTagUrl" class="form-control mb-2"
      placeholder="Enter Script Tag Url" /><br>
    <label for="updateScriptTag">Enter Script Tag ID:</label>
    <input type="text" id="updateScriptTag" [(ngModel)]="inputData.scriptTagID" class="form-control mb-2"
      placeholder="Enter Script Tag ID" />
    <button class="btn btn-warning" (click)="updateData()">Update Data</button>
    <p *ngIf="responseMessage" class="mt-2 text-info">{{ responseMessage }} </p>
    <p *ngIf="isGet">Please wait... Getting latest script tag info...</p>
  </div>

  <!-- DELETE Operation UI -->
  <div *ngIf="selectedOperation === 'DELETE'" class="mb-3">
    <label for="getShopDomain">Enter Shopify Domain:</label>
    <input type="text" id="getShopDomain" [(ngModel)]="inputData.shopifyDomain" class="form-control mb-2"
      placeholder="Enter Shopify Domain" /><br>
    <label for="updateScriptTag">Enter Script Tag ID:</label>
    <input type="text" id="updateScriptTag" [(ngModel)]="inputData.scriptTagID" class="form-control mb-2"
      placeholder="Enter Script Tag ID" />
    <button class="btn btn-danger" (click)="deleteData()">Delete Data</button>
    <p *ngIf="responseMessage" class="mt-2 text-info">{{ responseMessage }} </p>
    <p *ngIf="isGet">Please wait... Getting latest script tag info...</p>
  </div>

  <!-- Response Display -->
  <div *ngIf="responseData.length > 0" class="mt-3">
    <div class="table-responsive">
      <table class="table table-striped table-hover table-bordered align-middle shadow-sm">
        <thead class="table-dark">
          <tr>
            <th *ngFor="let key of responseData[0] | keyvalue" class="text-center">
              {{ key.key | titlecase }}
            </th>
            <th class="text-center">Action</th> <!-- Action column -->
          </tr>
        </thead>
        <tbody>
          <tr *ngFor="let item of responseData">
            <td *ngFor="let key of item | keyvalue" class="text-center">
              {{ key.value }}
            </td>
            <td class="text-center">
              <button class="btn btn-primary btn-sm" [disabled]="!item.src.includes('ngrok')"
                [title]="!item.src.includes('ngrok') ? 'Action unavailable for this src' : ''"
                (click)="onSwitchTag(item)">
                Switch To Live
              </button>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
  <br>
  <br>
  <!-- Back to Homepage Button -->
  <div style="margin-bottom: 20px;">
    <button class="btn btn-secondary larfButton" (click)="reloadHome()">Back to Homepage</button>
  </div>
</div>
