import { HttpClient} from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
// Import the AuthService type from the SDK
import { AuthService } from '@auth0/auth0-angular';

@Component({
  selector: 'app-auth-login',
  templateUrl: './auth-login.component.html',
  styleUrls: ['./auth-login.component.css']
})
export class AuthLoginComponent implements OnInit {
  profileJson: string;
  selectedRole: any;
  selectedEnv: any;
  role_msg;
  token_status;
  isDisplay: boolean = true;
  isCopyText: boolean = false;
  isWorkingEnv: boolean = false;
  isLogin: boolean = false;
  tokenRole;
  token_info;
  acc_token_info;
  env_msg;
  realm;
  key_realm;
  key_token;
  key_lb4b_token;
  paramsObject;
  paramsInfo;
  shScriptRole;
  scriptOperationMessage: any;
  visible: boolean = false;
  inVisible: boolean = true;
  isParams: boolean = false;
  isScriptTag: boolean = false;
  isTokenPage: boolean = true;
  isScriptInput: boolean = false;
  showMessage: boolean = false;

  constructor(
    public auth: AuthService,
    private router: Router,
    private route: ActivatedRoute,
    private http: HttpClient
  ) {}

  ngOnInit(): void {
    this.route.queryParamMap.subscribe((params) => {
      this.paramsObject = { ...params.keys, ...params };
      // console.log(this.paramsObject);
      if (
        this.paramsObject.params &&
        this.paramsObject.params.role != undefined &&
        this.paramsObject.params.env != undefined
      ) {
        this.isDisplay = false;
        this.paramsInfo =
          'selected role is ' +
          "'" +
          this.paramsObject.params.role +
          "' and environment is " +
          "'" +
          this.paramsObject.params.env +
          "'";
          this.loginWithRedirect()
      }
    });

    this.auth.user$.subscribe((profile) => {
      this.profileJson = JSON.stringify(profile);
    });

    this.auth.idTokenClaims$.subscribe((claims) => {
      if (claims) {
        // console.log('inside claims');
        var kcToken = claims['http://localhost:4200/tokenFromSails'];
        var tokenRole = claims['http://localhost:4200/selectedRole'];
        this.shScriptRole = tokenRole;
        var tokenEnv = claims['http://localhost:4200/selectedEnv'];
        // console.log('info from auth0:', kcToken, tokenRole, tokenEnv);
        if (tokenRole == 'undefined') {
          this.isDisplay = false;
          this.isWorkingEnv = false;
          this.showMessage = false;
          this.token_status =
            'selected role is undefined, pls logout and login again with role selected';
        } else {
          this.isDisplay = false;
          this.isWorkingEnv = false;
          this.showMessage = false;
          this.token_status =
            'kc token info available now for selected ' +
            tokenRole +
            ' role and ' +
            tokenEnv +
            ' env.';
          this.isCopyText = true;
          if (this.shScriptRole == 'lb4b-employee') {
            this.isScriptTag = true;
          } else {
            this.isScriptTag = false;
            this.scriptOperationMessage =
              'script operations are available to lb4b-employee role only';
            console.log('scriptOperationMessage:', this.scriptOperationMessage);
          }
          this.acc_token_info = 'Bearer ' + kcToken.access_token;
          this.key_token = 'authorization';
          this.key_realm = 'realmname';
          this.key_lb4b_token = 'lb4b-token';
          var kct_realm = atob(kcToken.access_token.split('.')[1]);
          var realm_in = JSON.parse(kct_realm)['iss'].split('/');
          this.realm = realm_in[realm_in.length - 1];
          this.token_info = JSON.stringify(kcToken);
          document.cookie =
            'authorization=Bearer ' +
            kcToken.access_token +
            ';path=/;domain=.liveb4buy.com;';
          document.cookie =
            'realmname=' +
            realm_in[realm_in.length - 1] +
            ';path=/;domain=.liveb4buy.com;';
          document.cookie =
            'authorization=Bearer ' +
            kcToken.access_token +
            ';path=/;domain=.livearf.com;';
          document.cookie =
            'realmname=' +
            realm_in[realm_in.length - 1] +
            ';path=/;domain=.livearf.com;';
        }
      }
    });

    this.auth.error$.subscribe((error) => {
      console.log(error);
      // this.auth.logout({ returnTo: window.location.origin });
    });
  }

  // getting roles
  rolesList: any = ['lb4b-employee', 'lb4b-admin', 'lb4b-seller', 'lb4b-buyer'];

  form1 = new FormGroup({
    roles: new FormControl('', Validators.required)
  });

  get f1() {
    return this.form1.controls;
  }

  submit1() {
    this.selectedRole = this.form1.value;
    this.isDisplay = false;
    this.isWorkingEnv = true;
    this.showMessage = true;
    // let default_role = 'previously selected role'
    this.role_msg = "selected role is " + "'" + this.selectedRole.roles + "'";
  }

  // getting envs
  workingEnvList: any = ['dev', 'prod'];

  form2 = new FormGroup({
    workingEnv: new FormControl('', Validators.required)
  });

  get f2() {
    return this.form2.controls;
  }

  submit2() {
    this.selectedEnv = this.form2.value;
    this.isDisplay = false;
    this.isWorkingEnv = false;
    this.showMessage = true;
    // let default_role = 'previously selected role'
    this.env_msg =
      'selected env is ' +
      "'" +
      this.selectedEnv.workingEnv +
      "'" +
      ', please click login';
    this.isLogin = true;
  }

  // auth0 login
  loginWithRedirect(): void {
    // console.log('in auth0 login');
    if (
      this.paramsObject.params &&
      this.paramsObject.params.role != undefined &&
      this.paramsObject.params.env != undefined
    ) {
      console.log('inside login from queryparams');
      this.auth.loginWithRedirect({
        role: this.paramsObject.params.role,
        env: this.paramsObject.params.env,
      });
    } else {
      console.log('inside login from form selection');
      this.auth.loginWithRedirect({
        role: this.selectedRole.roles,
        env: this.selectedEnv.workingEnv,
      });
    }
    // old login method
    // this.auth.loginWithRedirect({ role: this.selectedRole.roles, env: this.selectedEnv.workingEnv });
  }

  // auth0 logout
  logout(): void {
    console.log('logged out!!!');
    this.auth.logout({ returnTo: window.location.origin });
  }

  updateScript() {
    this.isTokenPage = false;
    // console.log('inside update script:', this.shScriptRole);
    this.isScriptTag = false;
    this.router.navigate(['/script-tags'], {
      queryParams: { param1: this.realm, param2: this.token_info },
    });
  }
}
